import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  makeVar,
} from '@apollo/client'
import processLocale from '@lib/locale'
import commerceConfig from './config'

const {
  config: { context },
} = commerceConfig
const initToken: string | null = ''
export const customerAccessTokenVar = makeVar(initToken)
export const storefrontCustomerAccessTokenVar = makeVar(initToken)

const contextLink = new ApolloLink((operation, forward) => {
  const { locale, customerAccessToken, useCustomerApi } = operation.getContext()
  const currentLocale = processLocale(locale)
  const currentContext = context?.[currentLocale]
  const shopId = currentContext?.shopifyHeadlessShopId

  if (
    !customerAccessToken &&
    (!currentContext ||
      !currentContext?.shopifyDomain ||
      !currentContext?.shopifyAPIToken)
  ) {
    throw new Error(
      `The shopify env settings are missing for processLocale: ${currentLocale} and locale: ${locale}`
    )
  }
  if (customerAccessToken && !shopId) {
    throw new Error(
      `The shopid env settings are missing for processLocale: ${currentLocale} and locale: ${locale}`
    )
  }
  if (useCustomerApi) {
    operation.setContext(() => ({
      uri: `https://shopify.com/${shopId}/account/customer/api/2024-07/graphql`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: customerAccessToken!,
      },
    }))
  } else {
    operation.setContext(() => ({
      uri: `https://${currentContext.shopifyDomain}/api/2024-07/graphql.json`,
      headers: {
        'X-Shopify-Storefront-Access-Token': currentContext.shopifyAPIToken,
        'Accept-Language': currentLocale,
      },
    }))
  }

  return forward(operation)
})

const client = new ApolloClient({
  link: from([contextLink, new HttpLink()]),
  cache: new InMemoryCache(),
})

export default client
