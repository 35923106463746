const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID
export const EVENT_DEBUG = process.env.NEXT_PUBLIC_EVENT_DEBUG
export default GTM_ID

export const infoLog = (message: string) => {
  console.log(
    '%c%s',
    'color: white; background: green; padding: 2px 10px;',
    message
  )
}

export const eventLog = (message: string, event: any) => {
  console.log(
    '%c%s',
    'color: white; background: teal; padding: 2px 10px;',
    message,
    event ? event.detail : 'No event details available.'
  )
}
