import React, { FC, useCallback, useMemo } from 'react'

export interface State {
  listOwnerEmail: string | null
  tempListId: string | null
  userTempListId: string | null
}

const initialState = {
  listOwnerEmail: null,
  tempListId: null,
  userTempListId: null,
}

type Action =
  | {
      type: 'SET_LIST_OWNER_EMAIL'
      listOwnerEmail: string | null
    }
  | {
      type: 'SET_TEMP_LIST_ID'
      tempListId: string | null
    }
  | {
      type: 'SET_USER_TEMP_LIST_ID'
      userTempListId: string | null
    }
export type ListItemActionData = {
  product: any
  quickAddProductHandle: string
  parentProduct?: {
    handle: string
    type: string | undefined
    colourCode: string | null | undefined
  }
  listId?: string | null
}
export const ListContext = React.createContext<State | any>(initialState)

ListContext.displayName = 'ListContext'

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'SET_LIST_OWNER_EMAIL': {
      return {
        ...state,
        listOwnerEmail: action.listOwnerEmail,
      }
    }
    case 'SET_TEMP_LIST_ID': {
      return {
        ...state,
        tempListId: action.tempListId,
      }
    }
    case 'SET_USER_TEMP_LIST_ID': {
      return {
        ...state,
        userTempListId: action.userTempListId,
      }
    }
  }
}

export const ListProvider: FC<any> = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState)

  const setListOwnerEmail = useCallback(
    (listOwnerEmail: string) =>
      dispatch({
        type: 'SET_LIST_OWNER_EMAIL',
        listOwnerEmail,
      }),
    [dispatch]
  )
  const setTempListId = useCallback(
    (tempListId: string) =>
      dispatch({
        type: 'SET_TEMP_LIST_ID',
        tempListId,
      }),
    [dispatch]
  )

  const setUserTempListId = useCallback(
    (userTempListId: string) =>
      dispatch({
        type: 'SET_USER_TEMP_LIST_ID',
        userTempListId,
      }),
    [dispatch]
  )
  const value = useMemo(
    () => ({
      ...state,
      setListOwnerEmail,
      setTempListId,
      setUserTempListId,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )

  return <ListContext.Provider value={value} {...props} />
}

export const useArkadeList = () => {
  const context = React.useContext(ListContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

interface Props {
  children: React.ReactNode
}
export const ArkadeListContext: FC<Props> = ({ children }) => (
  <ListProvider>{children}</ListProvider>
)
