module.exports = {
  i18n: {
    defaultLocale: 'en-au',
    locales: ['en-au', 'en-us', 'es-us', 'en-gb', 'en-eu', 'de-eu', 'es-eu'],
    localeDetection: false,
  },
  debug: false,
  localePath:
    typeof window === 'undefined'
      ? require('path').resolve('./public/locales')
      : '/locales',
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  serializeConfig: false,
  nonExplicitSupportedLngs: true,
}
