import commerceConfig from '@commerce/config'
import { getConfigByLocale } from '@lib/locale'
import { useCustomerPrivacy } from '@shopify/hydrogen'
import Script from 'next/script'

//    Shopify consent
//     marketing: true,
//     analytics: true,
//     preferences: true,
//     sale_of_data: true,

const GTM_CONTAINER_WITH_CONSET = () => {
  const {
    config: { defaultLocale },
  } = commerceConfig
  const storefrontAccessToken = getConfigByLocale(
    defaultLocale,
    'shopifyAPIToken'
  )
  const shopifyDomain = getConfigByLocale(defaultLocale, 'shopifyDomain')
  const { customerPrivacy } = useCustomerPrivacy({
    storefrontAccessToken,
    checkoutDomain: shopifyDomain,
  })

  const loadedConsent = {
    analytics_storage:
      customerPrivacy && customerPrivacy.analyticsProcessingAllowed()
        ? 'granted'
        : 'denied',
    ad_storage:
      customerPrivacy && customerPrivacy.analyticsProcessingAllowed()
        ? 'granted'
        : 'denied',
    personalization_storage:
      customerPrivacy && customerPrivacy.preferencesProcessingAllowed()
        ? 'granted'
        : 'denied',
  }

  const CONSET_SETUP = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('consent', 'default', ${loadedConsent});
  `
  return (
    <Script
      id="gtm-script"
      dangerouslySetInnerHTML={{
        __html: `${CONSET_SETUP}(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`,
      }}
      strategy="afterInteractive"
    />
  )
}

export default process.env.NEXT_PUBLIC_GTM_ID ? (
  <GTM_CONTAINER_WITH_CONSET />
) : (
  ''
)
