import { SEO } from '@components/common'
import type { VFC } from 'react'
import gtm from './gtm'

const Head: VFC = () => {
  return (
    <>
      <SEO>
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1.0"
        />
        <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
      </SEO>
      {gtm}
    </>
  )
}

export default Head
