import { getConfigByLocale } from '@lib/locale'
import * as prismic from '@prismicio/client'
import * as prismicNext from '@prismicio/next'
import { PrismicDocument, Slice } from '@prismicio/types'
import _, { lowerCase } from 'lodash'
import config from './slicemachine.config.json'

/**
 * The project's Prismic repository name.
 */
export const repositoryName =
  process.env.NEXT_PUBLIC_PRISMIC_ENVIRONMENT || config.repositoryName

/**
 * A list of Route Resolver objects that define how a document's `url` field is resolved.
 *
 * {@link https://prismic.io/docs/route-resolver#route-resolver}
 */
// TODO: Update the routes array to match your project's route structure.

export const prismicFakeLocaleTags = ['en-us', 'en-gb', 'en-eu']
export const localesHoldingFakeLocales = ['en-au']

const routes: prismic.ClientConfig['routes'] = [
  // Examples:
  // {
  //   type: 'homepage',
  //   path: '/:lang/',
  // },
  {
    type: 'general',
    path: '/:lang/:uid',
  },
  {
    type: 'blog-landing-page',
    path: '/:lang/the-alchemist',
  },
  {
    type: 'article',
    path: '/:lang/article/:uid',
  },
  {
    type: 'article-listing-page',
    path: '/:lang/articles/:uid',
  },
]

// Update the Link Resolver to match your project's route structure
export function linkResolver(doc: any) {
  let linkPrefix = doc?.lang !== 'en-au' ? `/${doc?.lang}/` : '/'
  const tags = doc.tags
  let uid = doc.uid
  const fakeLocalFound = prismicFakeLocaleTags.filter((t) => tags.includes(t))
  const prismicFakeLocale = fakeLocalFound?.[0]
  if (prismicFakeLocale) {
    linkPrefix = `/${prismicFakeLocale}/`
    uid = removeFakeConcatedPartFromUid(uid, prismicFakeLocale)
  }

  if (doc?.uid === 'homepage') return linkPrefix
  switch (doc.type) {
    case 'general':
      return `${linkPrefix}${uid?.split('.').join('/')}`
    case 'blog-landing-page':
      return `${linkPrefix}the-alchemist`
    case 'article':
      return `${linkPrefix}article/${uid}`
    case 'article-listing-page':
      return `${linkPrefix}articles/${uid}`
    default:
      return linkPrefix
  }
}

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config - Configuration for the Prismic client.
 */
export const createClient = (config: prismicNext.CreateClientConfig = {}) => {
  const client = prismic.createClient(repositoryName, {
    routes,
    ...config,
  })

  prismicNext.enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  })

  return client
}

export const getDocByUid = async (
  doctype: string,
  uid: string,
  client: prismic.Client,
  locale: string | undefined,
  fetchLinks?: string[],
  fallback: boolean = true,
  fallbackDocType?: string
) => {
  if (!uid) return null
  const prismicLocale = getConfigByLocale(locale, 'prismicLocale') || locale
  let doc = null

  try {
    doc = await client.getByUID(
      doctype,
      prismicLocale !== locale ? addFakeConcatedPartToUid(uid, locale!) : uid,
      {
        lang: prismicLocale,
        fetchLinks: fetchLinks,
      }
    )
  } catch (e) {}
  if (!doc && fallback)
    doc = await client.getByUID(fallbackDocType || doctype, uid, {
      fetchLinks: fetchLinks,
    })

  // make sure actual prismic locale eg. en-au does not return document from fake locales eg en-us
  if (
    (!locale || localesHoldingFakeLocales.includes(locale)) &&
    doc?.tags &&
    Array.isArray(doc.tags)
  ) {
    const found = doc.tags.some((r) => prismicFakeLocaleTags.includes(r))
    if (found) return null
  }

  return doc
}

export const getAllDocByType = async (
  doctype: string,
  client: prismic.Client,
  locale: string | undefined,
  fallback: boolean = true
) => {
  const prismicLocale = getConfigByLocale(locale, 'prismicLocale') || locale
  if (!doctype) return null
  let doc = null
  try {
    doc = await client.getAllByType(doctype, { lang: prismicLocale })
  } catch (e) {}
  if (!doc && fallback) doc = await client.getAllByType(doctype)
  if (Array.isArray(doc) && doc.length === 0 && fallback)
    doc = await client.getAllByType(doctype)

  // filter out duplicate doucments eg. contact-us and contact-us-en-us or contat-us-${fakeLocale}
  if (prismicLocale !== locale) {
    const docIdsForFakeLocale = doc
      ?.filter((d) => d?.tags?.includes(locale!))
      .map((d) => d.uid)
    doc = doc
      ?.filter(
        (d) =>
          d?.tags?.includes(locale!) ||
          !d.tags.some((t) => prismicFakeLocaleTags.includes(t))
      )
      .filter(
        (d) =>
          !docIdsForFakeLocale?.includes(
            addFakeConcatedPartToUid(d.uid!, locale!)
          )
      )
  }

  return doc
}

export const findCollectionHandles = (sliceList: Slice[]) => {
  const collectionHandles = sliceList
    ?.filter((slice: any) => slice.slice_type === 'product_slider')
    .map((slice: any) => slice?.primary?.shopifyCollectionHandle)

  return collectionHandles || []
}

export const findPrismicUidFromTags = (tags: string[]) => {
  const uid = tags?.find((tag: string) => tag.startsWith('prismic:'))
  return uid ? uid.replace('prismic:', '') : null
}

export const getSingleDocByType = async (
  doctype: string,
  client: prismic.Client,
  locale: string | undefined,
  fallBack = true
) => {
  const prismicLocale = getConfigByLocale(locale, 'prismicLocale') || locale
  let doc = null
  try {
    doc = await client.getSingle(doctype, { lang: prismicLocale })
  } catch (e) {}

  if (fallBack && !doc) doc = await client.getSingle(doctype)

  return doc
}

export const OrderByPublishDate = (
  items: PrismicDocument<Record<string, any>, string, string>[] | null,
  handle: string
) => {
  const sortedItems = _.orderBy(
    items?.filter(
      (article: any) =>
        article?.data?.blogCategory.replace(/\s+/g, '').toLowerCase() ===
        lowerCase(handle)
    ),
    (item: any) => {
      return item.first_publication_date
    },
    'desc'
  )
  return sortedItems
}

export const removeFakeConcatedPartFromUid = (uid: string, locale: string) => {
  const concatedPart = `-${locale?.replace('-', '_')}`
  return uid.replace(concatedPart, '')
}

export const addFakeConcatedPartToUid = (uid: string, locale: string) => {
  const concatedPart = `-${locale?.replace('-', '_')}`
  return `${uid}${concatedPart}`
}
