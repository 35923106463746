import { eventLog, EVENT_DEBUG, infoLog } from './general'

declare global {
  interface Window {
    dataLayer: any
  }
}

export const pageView = (url: string) => {
  if (EVENT_DEBUG && !window?.dataLayer) {
    infoLog('PageView: No Data Layer found.')
  }

  if (window?.dataLayer) {
    const detail = {
      event: 'pageview',
      page: url,
    }

    window?.dataLayer?.push(detail)
    if (EVENT_DEBUG) eventLog(`View Page 'gtm.PageView (custom)`, { detail })
    window?.dispatchEvent(new CustomEvent('CustomPageViewTriggered'))
  }
}
